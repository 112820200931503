import axios from "axios";

let apiUrlBase = 'https://api.radiopolis.xyz/';

export function getCurrentProgram() {
    return axios
        .get(apiUrlBase + 'program.php')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}